// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$munawel-frontend-primary: mat.define-palette(mat.$indigo-palette);
$munawel-frontend-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$munawel-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$munawel-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $munawel-frontend-primary,
      accent: $munawel-frontend-accent,
      warn: $munawel-frontend-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($munawel-frontend-theme);

@import "~quill/dist/quill.snow.css";

$small: 768px;
$smallMedium: 1100px;

:root {
  --primary-color: #5a1d59;
  --white-color: #fff;
  --black-color: #2e2e2e;
  // --small: 768px;
  // --smallMedium: 1100px;
}

html,
body,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Lexend", sans-serif !important;
  font: inherit;
  font-weight: 300;
  font-size: 14px;
  color: #16151c;
  letter-spacing: normal;
  vertical-align: baseline;
  box-sizing: border-box;
}

// span and div excluded from reset css for AG Grid
div {
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  opacity: 0.8;
}

// style variables
// color

// style variables ends

/* Core Data Grid CSS */
@import "ag-grid-community/styles/ag-grid.css";
/* Quartz Theme Specific CSS */
@import "ag-grid-community/styles/ag-theme-quartz.css";

// common styles

.cmn-heading-1 {
  font-size: 20px;
  font-weight: 400;
}

.cmn-inner-heading {
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
}

.cmn-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  @media screen and (max-width: $small) {
    font-size: 18px;
  }
}

.cmn-sub-title {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;

  ul {
    li {
      font-size: 13px;
      display: inline-block;
      color: #737377;
      margin-right: 35px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 2px;
        right: -24px;
        width: 10px;
        height: 10px;
        transform: rotate(-90deg);
        background-image: url("assets/images/icons/arrow.svg");
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: 0 0;
      }

      &:last-child {
        margin-right: 0;

        &::after {
          display: none;
        }
      }

      a {
        text-decoration: none;
      }

      @media screen and (max-width: $small) {
        margin-top: 0;
      }
    }
  }

  .back {
    font-weight: 500;
    display: flex;
    align-items: center;
    color: var(--primary-color);
    cursor: pointer;

    img {
      margin-right: 5px;
    }
  }

  @media screen and (max-width: $small) {
    margin: 0;
  }
}

.cmn-popup-title {
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.cmn-popup-title-big {
  color: var(--primary-color);
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.cmn-popup-btn {
  width: 300px;
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 16px;
  border: 0;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
  font-family: "Lexend", sans-serif !important;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-color: var(--black-color);
  }
}

.cmn-innerpage-wrapper {
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #ece8ff;
  height: calc(100vh - 115px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 1350px) {
    height: calc(100vh - 157px);
  }

  @media screen and (max-width: $small) {
    padding: 0;
    padding-top: 20px;
    border: 0;
    border-radius: 0;
    border-top: 1px solid #ece8ff;
    margin-top: 10px;
    height: calc(100vh - 137px);
  }
}

.cmn-upload-btn {
  background-color: #f1eefe;
  padding: 20px;
  border: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-family: "Lexend", sans-serif !important;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    padding: 14px;
  }

  img {
    margin-right: 10px;
  }
}

.cmn-add-btn {
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 20px;
  border: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-family: "Lexend", sans-serif !important;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-color: var(--black-color);
  }

  img {
    margin-right: 10px;
  }

  @media screen and (max-width: 480px) {
    padding: 14px;
  }
}

.cmn-settings-btn {
  background-color: var(--white-color);
  border: 1px solid #ece8ff;
  color: var(--primary-color);
  font-weight: 500;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-family: "Lexend", sans-serif !important;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 480px) {
    padding: 14px;
  }

  &:hover {
    background-color: #f1eefe;
  }

  img {
    margin-right: 10px;
  }
}

.cmn-filter-btn {
  position: relative;
  background-color: var(--white-color);
  border: 1px solid #ece8ff;
  padding: 17px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in-out;
  font-size: 15px;
  font-weight: 400;
  gap: 10px;
  font-family: "Lexend", sans-serif !important;

  &:hover {
    background-color: #f1eefe;
  }

  @media screen and (max-width: 480px) {
    padding: 13px;
  }

  img {
    width: 22px;
  }

  span {
    position: absolute;
    top: -10px;
    right: -7px;
    background-color: var(--primary-color);
    color: var(--white-color);
    font-weight: 400;
    font-size: 11px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.cmn-export-btn {
  background-color: var(--white-color);
  border: 1px solid #ece8ff;
  padding: 17px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media screen and (max-width: 480px) {
    padding: 13px;
  }

  img {
    width: 22px;
  }
}

.cmn-reject-btn {
  background-color: var(--white-color);
  border: 1px solid #ff4646;
  color: #ff4646;
  padding: 16px;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Lexend", sans-serif !important;
  font-size: 14px;
  width: 200px;
  align-items: center;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 480px) {
    padding: 13px;
  }

  &:hover {
    background-color: #ffe4e4;
  }
}

.leave-cancel {
  all: unset;
  font-weight: 400;
  color: #ab0010;
}

.cmn-cancel-btn {
  background-color: var(--white-color);
  border: 1px solid #ece8ff;
  padding: 16px;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Lexend", sans-serif !important;
  font-size: 14px;
  width: 200px;
  align-items: center;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 480px) {
    padding: 13px;
  }

  &:hover {
    background-color: #f1eefe;
  }
}

.cmn-draft-btn {
  background-color: var(--white-color);
  color: var(--primary-color);
  border: 1px solid #ece8ff;
  padding: 16px;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Lexend", sans-serif !important;
  font-size: 14px;
  width: 200px;
  align-items: center;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 480px) {
    padding: 13px;
  }

  &:hover {
    background-color: #f1eefe;
  }
}

.cmn-next-btn {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: 0;
  padding: 16px;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Lexend", sans-serif !important;
  font-size: 14px;
  width: 200px;
  align-items: center;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 480px) {
    padding: 13px;
  }

  &:hover {
    background-color: var(--black-color);
  }
}

.cmn-choose-file-btn {
  background-color: var(--white-color);
  color: var(--primary-color);
  border: 1px solid #ece8ff;
  padding: 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-family: "Lexend", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 480px) {
    padding: 13px;
  }

  &:hover {
    background-color: #f1eefe;
  }

  img {
    margin-right: 10px;
  }
}

.cmn-upload-box {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gainsboro' stroke-width='4' stroke-dasharray='15%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 15px;
  text-align: center;

  h4 {
    color: #676767;
    margin: 18px 0 20px 0;
  }
}

.cmn-apply-btn {
  width: 100%;
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 16px;
  border: 0;
  border-radius: 10px;
  font-family: "Lexend", sans-serif !important;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-color: var(--black-color);
  }
}

.cmn-close {
  position: absolute;
  top: 25px;
  right: 25px;
  background-image: url("assets/images/icons/icon-close.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 0;
  cursor: pointer;
}

.cmn-upload-submit-btn.active {
  background-color: var(--primary-color);
}

.cmn-upload-submit-btn {
  width: 100%;
  text-align: center;
  background-color: #9d9d9d;
  color: var(--white-color);
  padding: 16px;
  border: 0;
  border-radius: 10px;
  margin-top: 18px;
  font-family: "Lexend", sans-serif !important;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-color: var(--black-color);
  }
}

.cmn-popup-heading {
  font-weight: 600;
  font-size: 16px;
  padding-right: 25px;

  span {
    margin-left: 6px;
    font-weight: 400;
    font-size: 12px;
    color: #757575;
    border: 1px solid #c9c9c9;
    padding: 2px 7px;
    border-radius: 18px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &:hover {
      background-color: #f1eefe;
    }
  }
}

.cmn-header-search {
  padding: 19px 20px 19px 50px;
  border-radius: 10px;
  border: 0;
  width: 100%;
  box-sizing: border-box;
  background: #faf9ff;
  background-image: url("assets/images/icons/icon-search.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 15px;
  font-family: "Lexend", sans-serif !important;
  font-size: 14px;

  &::placeholder {
    font-weight: 300;
    color: #717171;
  }

  &:focus-visible {
    outline: none;
  }
}

.common-dropdown {
  width: 400px;
  background-color: var(--white-color);
  position: absolute;
  box-shadow: 0px 4px 43px rgba(0, 0, 0, 0.12);
  padding: 25px 22px 25px 25px;
  border-radius: 20px;
  z-index: 5;
  display: none;
  transition: all 0.4s ease-in-out !important;
  max-height: 500px;

  h2 {
    font-weight: 500;
    margin-top: 10px;
  }

  .check-wrapper {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .check-box {
      width: 50%;
      margin: 10px 0;
    }
  }

  .radio-wrapper {
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;

    .radio-box {
      margin: 10px 15px 10px 0;
    }
  }

  .close-dropdown {
    position: absolute;
    top: 15px;
    right: 15px;
    background-image: url("assets/images/icons/icon-close.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 0;
    cursor: pointer;
  }
}

.common-dropdown.show {
  display: block;
}

.filter-inner-content {
  margin-top: 10px;
  margin-bottom: 18px;
  padding-right: 15px;
  overflow: auto;
  max-height: 235px;
}

.employee-tab-wrapper {
  .tab-content-wrapper {
    margin-top: 30px;
  }

  .cmn-tab {
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-size: 16px;
    padding-left: 25px;
    line-height: 2;
    font-weight: 400;
    letter-spacing: normal;
  }

  .tab-1 {
    background-image: url("assets/images/icons/icon-personal-plain.svg");
  }

  .tab-2 {
    background-image: url("assets/images/icons/icon-professional-plain.svg");
  }

  .tab-3 {
    background-image: url("assets/images/icons/icon-documents-plain.svg");
  }

  .tab-4 {
    background-image: url("assets/images/icons/icon-access-plain.svg");
  }

  .tab-5 {
    background-image: url("assets/images/icons/icon-salary-plain.svg");
  }

  .tab-6 {
    background-image: url("assets/images/icons/icon-career-plain.svg");
  }

  // Mat Tab Styles

  .mat-mdc-tab.mdc-tab--active h2 {
    color: var(--primary-color) !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-1 {
    background-image: url("assets/images/icons/icon-personal-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-2 {
    background-image: url("assets/images/icons/icon-professional-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-3 {
    background-image: url("assets/images/icons/icon-documents-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-4 {
    background-image: url("assets/images/icons/icon-access-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-5 {
    background-image: url("assets/images/icons/icon-salary-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-6 {
    background-image: url("assets/images/icons/icon-career-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab {
    flex-grow: 0 !important;
    padding: 0 10px;
    margin-right: 35px;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: var(--primary-color) !important;
  }

  // Mat Tab Styles Ends
}

.employee-details-tab-wrapper {
  border: 1px solid #ece8ff;
  border-radius: 10px;
  overflow: hidden;

  .tab-content-wrapper {
    margin-top: 30px;
  }

  h2 {
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-size: 16px;
    padding-left: 25px;
    line-height: 2;
    font-weight: 400;
    letter-spacing: normal;
  }

  .tab-1 {
    background-image: url("assets/images/icons/icon-personal-plain.svg");
  }

  .tab-2 {
    background-image: url("assets/images/icons/icon-attendance.svg");
  }

  .tab-3 {
    background-image: url("assets/images/icons/icon-projects.svg");
  }

  .tab-4 {
    background-image: url("assets/images/icons/icon-leaves.svg");
  }

  .tab-5 {
    background-image: url("assets/images/icons/icon-exit.svg");
  }

  .tab-6 {
    background-image: url("assets/images/icons/icon-profile-assets.svg");
  }

  .tab-7 {
    background-image: url("assets/images/icons/icon-profile-security.svg");
  }

  .tab-8 {
    background-image: url("assets/images/icons/icon-profile-vacation.svg");
  }

  // Mat Tab Styles

  // .mat-mdc-tab-labels {
  //   flex-direction: column;
  //   align-items: baseline;
  // }

  .mat-mdc-tab.mdc-tab--active {
    background-color: var(--primary-color);
    color: var(--white-color);
  }

  .mat-mdc-tab.mdc-tab--active h2 {
    color: var(--white-color) !important;
    filter: brightness(0) invert(1);
  }

  .mat-mdc-tab.mdc-tab--active .tab-1 {
    background-image: url("assets/images/icons/icon-personal-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-2 {
    background-image: url("assets/images/icons/icon-attendance-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-3 {
    background-image: url("assets/images/icons/icon-projects-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-4 {
    background-image: url("assets/images/icons/icon-leaves-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-5 {
    background-image: url("assets/images/icons/icon-exit-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-6 {
    background-image: url("assets/images/icons/icon-profile-assets-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-7 {
    background-image: url("assets/images/icons/icon-profile-security-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab--active .tab-8 {
    background-image: url("assets/images/icons/icon-profile-vacation-fill.svg") !important;
  }

  .mat-mdc-tab.mdc-tab {
    padding: 0 30px;
    // width: 100%;
    height: 56px;
    justify-content: left;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: var(--primary-color) !important;
  }

  // Mat Tab Styles Ends
}

// Reports Tabs Styles

.reports-tab-wrapper {
  overflow: hidden;

  .tab-content-wrapper {
    margin-top: 30px;
  }

  h2 {
    color: #6e6e6e;
    line-height: 2;
    font-weight: 400;
    letter-spacing: normal;
  }

  // Mat Tab Styles

  .mat-mdc-tab-header-pagination-controls-enabled
    .mat-mdc-tab-header-pagination {
    display: none !important;
  }

  .mat-mdc-tab-labels {
    flex-direction: column;
    align-items: baseline;
  }

  // .mat-mdc-tab.mdc-tab--active {
  //   background-color: var(--primary-color);
  //   color: var(--white-color);
  // }

  .mat-mdc-tab.mdc-tab--active h2 {
    color: var(--primary-color) !important;
    font-weight: 500;
  }

  .mat-mdc-tab.mdc-tab {
    padding: 0;
    width: 100%;
    height: 50px;
    justify-content: left;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    display: none;
  }

  @media screen and (max-width: $smallMedium) {
    .mat-mdc-tab-header-pagination-controls-enabled
      .mat-mdc-tab-header-pagination {
      display: unset !important;
    }

    .mat-mdc-tab-labels {
      flex-direction: row;
    }

    .mat-mdc-tab.mdc-tab {
      width: unset;
      padding: 0 20px;
    }
  }

  // Reports Tabs Styles Ends
}

// Manual Journal Details Styles

// .manual-journal-details-tab-wrapper {
//   overflow: hidden;

//   .tab-content-wrapper {
//     margin-top: 30px;
//   }

//   // Mat Tab Styles

//   .mat-mdc-tab .mdc-tab__content {
//     width: 100%;
//   }

//   .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
//     display: none !important;
//   }

//   .mat-mdc-tab .mdc-tab__text-label {
//     display: block;
//   }

//   .mat-mdc-tab-labels {
//     flex-direction: column;
//     align-items: baseline;
//     gap: 10px;
//   }

//   // .mat-mdc-tab.mdc-tab--active {
//   //   background-color: var(--primary-color);
//   //   color: var(--white-color);
//   // }

//   .mat-mdc-tab.mdc-tab--active {
//     background-color: #F9F8FF;
//     border: 1px solid #F9F8FF !important;
//   }

//   .mat-mdc-tab.mdc-tab {
//     border-radius: 10px;
//     border: 1px solid #ece8ff;
//     padding: 0 20px;
//     width: 100%;
//     height: 84px;
//     justify-content: left;
//     gap: 20px;
//   }

//   .mat-mdc-tab .mdc-tab-indicator__content--underline {
//     display: none;
//   }

//   // Chart Of Accounts Styles Ends
// }

// Timeline Styles

.timelinne-wrapper {
  margin-top: 25px;

  .timeline-box {
    margin-bottom: 25px;

    .timeline-head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      ul {
        display: flex;
        align-items: center;

        li {
          font-size: 12px;
          font-weight: 400;

          &:last-child {
            font-weight: 300;
            color: #8b8a8d;
            padding-left: 10px;
            margin-left: 10px;
            border-left: 1px solid #8b8a8d;
          }
        }
      }

      h3 {
        font-size: 12px;
        color: #8b8a8d;
      }
    }

    .timeline-content {
      margin-top: 20px;
      border-left: 2px solid var(--primary-color);
      padding-left: 20px;

      h4 {
        font-size: 12px;
        line-height: 1.4;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #ece8ff;
      }
    }
  }
}

// Timeline Styles

// Chart Of Accounts Styles

.chart-accounts-tab-wrapper {
  overflow: hidden;

  .tab-content-wrapper {
    margin-top: 30px;
  }

  h2 {
    font-weight: 400;
    letter-spacing: normal;
    display: block;
  }

  h3 {
    margin-top: 10px;
    font-size: 12px;
    color: #b3b2b9;
    letter-spacing: normal;
    text-align: left;
  }

  // Mat Tab Styles

  .mat-mdc-tab-header-pagination-controls-enabled
    .mat-mdc-tab-header-pagination {
    display: none !important;
  }

  .mat-mdc-tab .mdc-tab__text-label {
    display: block;
  }

  .mat-mdc-tab-labels {
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
  }

  // .mat-mdc-tab.mdc-tab--active {
  //   background-color: var(--primary-color);
  //   color: var(--white-color);
  // }

  .mat-mdc-tab.mdc-tab--active {
    background-color: #f9f8ff;
    border: 1px solid #f9f8ff !important;
  }

  .mat-mdc-tab.mdc-tab {
    border-radius: 10px;
    border: 1px solid #ece8ff;
    padding: 0 20px;
    width: 100%;
    height: 84px;
    justify-content: left;
    gap: 20px;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    display: none;
  }

  // Manual Journal Details Styles Ends
}

// Journal Table Styles

// .new-chart-accounts {
//   .form-wrapper .mat-mdc-text-field-wrapper {
//     background-color: #fff !important;
//     border: 1px solid #fff !important;
//   }

// }

// Journal Table Styles Ends

// Leave tab wrapper

.common-noimage-tab-wrapper {
  .leave-history-table {
    margin-top: 20px;
  }

  .leave-tab-title {
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-size: 16px;
    line-height: 2;
    font-weight: 400;
    letter-spacing: normal;
  }

  // Mat Tab Styles

  .mat-mdc-tab.mdc-tab--active h2 {
    color: var(--primary-color) !important;
  }

  .mat-mdc-tab.mdc-tab {
    flex-grow: 0 !important;
    padding: 0;
    margin-right: 35px;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: var(--primary-color) !important;
  }

  // Mat Tab Styles Ends
}

.payroll-tab-wrapper {
  background-color: #f1eefe;
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;

  .cmn-tab {
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-size: 16px;
    line-height: 2;
    font-weight: 400;
    padding: 6px;
    letter-spacing: normal;
    position: relative;

    span {
      display: block;
    }

    .sub-text {
      color: #a9a7b2;
      font-size: 13px;
    }
  }

  .locked {
    &::before {
      content: "";
      position: absolute;
      top: 2px;
      right: -8px;
      width: 10px;
      height: 12px;
      background-image: url("assets/images/icons/icon-lock.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 10px;
    }
  }

  .unlocked {
    &::before {
      content: "";
      position: absolute;
      top: 2px;
      right: -8px;
      width: 12px;
      height: 13px;
      background-image: url("assets/images/icons/icon-unlock.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px;
    }
  }

  // Mat Tab Styles

  .mat-mdc-tab.mdc-tab--active {
    background-color: var(--primary-color);
    color: var(--white-color);
  }

  .mat-mdc-tab.mdc-tab--active h2 {
    color: var(--white-color) !important;
    filter: brightness(0) invert(1);
  }

  .mat-mdc-tab.mdc-tab {
    flex-grow: 0 !important;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 5px;
    height: 70px;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    display: none;
  }

  // Mat Tab Styles Ends
}

// common styles ends

// common mat styles

// mat input ** login screen ** styles
.login-wrapper {
  .mat-mdc-text-field-wrapper {
    background-color: #fff !important;
    border: 1px solid #c9b5cc;
  }
}

// mat input ** login screen ** styles ends

// mat input ** header ** styles

.cmn-add-transaction-dropdown {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 21px;
    left: 15px;
    width: 15px;
    height: 15px;
    background-image: url("assets/images/icons/icon-add.svg");
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }

  .mat-mdc-text-field-wrapper {
    background-color: var(--primary-color) !important;

    @media screen and (max-width: $small) {
      padding: 0;
    }
  }

  .mat-mdc-select-arrow {
    color: #fff;
  }

  .mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
    color: #fff;
  }

  .mat-mdc-select-value-text {
    font-size: 14px;
    color: var(--white-color);
    font-family: "Lexend", sans-serif !important;
  }

  .mat-mdc-select-value {
    // width: auto;
    padding-right: 10px;
    padding-left: 28px;
    color: #fff;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.branch-wrapper {
  .mat-mdc-text-field-wrapper {
    background-color: #fff !important;

    @media screen and (max-width: $small) {
      padding: 0;
    }
  }

  .mat-mdc-select-value-text {
    font-size: 14px;
    color: var(--primary-color);
    font-family: "Lexend", sans-serif !important;
  }

  .mat-mdc-select-value {
    width: auto;
    padding-right: 10px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: var(--white-color) !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
    .mat-mdc-option-multiple
  ) {
  background-color: var(--primary-color) !important;
  border-radius: 5px !important;
}

div.mat-mdc-select-panel {
  border-radius: 10px !important;
  padding: 5px !important;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
  color: var(--white-color) !important;
}

// mat input ** header ** styles ends

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px !important;
}

.mat-mdc-form-field {
  width: 100%;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-floating-label--float-above {
  font-size: 14px;
}

.mdc-text-field--filled {
  border-radius: 10px !important;
}

.mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.mdc-line-ripple::before {
  display: none;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  display: none;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label--float-above {
  color: var(--primary-color) !important;
}

// mat dropdown styles

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.sidebar-wrapper {
  .mat-expansion-panel-body {
    padding: 0 0 16px !important;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 30px !important;
}

.form-wrapper {
  label {
    font-weight: 400;
    margin-bottom: 10px;
    display: block;
    color: #000;

    .red {
      display: inline !important;
    }
  }

  > label {
    text-transform: capitalize;
  }

  > div > label {
    text-transform: capitalize;
  }
}

.form-wrapper {
  .split-parent {
    position: relative;

    .left-set {
      width: 80%;
    }

    .right-set {
      // width: 25%;
      max-width: 100px;
      position: absolute;
      top: 0;
      right: 0;

      .mat-mdc-text-field-wrapper {
        background-color: #f2f2f2 !important;
        border: 1px solid #f2f2f2;
      }
    }
  }
}

.login-wrapper {
  .form-wrapper .mat-mdc-text-field-wrapper {
    border: 1px solid #c9b5cd;
  }
}

.form-wrapper .mat-mdc-text-field-wrapper {
  background-color: #fff !important;
  border: 1px solid #ece8ff;
}

.mdc-text-field__input,
.mat-mdc-select-value-text,
.mat-mdc-select-placeholder {
  font-family: "Lexend", sans-serif !important;
  font-size: 14px !important;
  letter-spacing: normal !important;

  &::placeholder {
    font-size: 14px !important;
  }
}

.mat-mdc-dialog-content {
  max-height: 75vh !important;

  .checkmark {
    top: 1px;
  }
}

.mdc-tab {
  min-width: 70px !important;
}

// common mat styles ends

// mat progressbar styles

.mdc-linear-progress__buffer-bar {
  background-color: #ebebeb !important;
}

.mdc-linear-progress__bar-inner {
  border-color: var(--primary-color) !important;
}

// mat progressbar styles ends

// mat toggle styles

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  font-family: "Lexend", sans-serif !important;
  font-size: 14px !important;
  padding: 0 30px !important;
}

.mat-button-toggle .mat-pseudo-checkbox {
  display: none;
}

.mat-button-toggle-group-appearance-standard {
  border-radius: 10px !important;
}

.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}

// mat toggle styles ends

// common checkbox

.checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  font-weight: 300;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -4px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid #ece8ff;
  background-color: var(--white-color);
}

/* On mouse-over, add a grey background color */
.checkbox-wrapper:hover input ~ .checkmark {
  background-color: #f2efff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-wrapper input:checked ~ .checkmark {
  background-color: var(--primary-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-wrapper .checkmark:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// Custom Radio Styles

/* The container */
.radiobtn-wrapper {
  display: block;
  position: relative;
  padding-left: 30px;
  font-weight: 300;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radiobtn-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radiomark {
  position: absolute;
  top: -4px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #ece8ff;
  background-color: var(--white-color);
}

/* Create a custom radio button */
.radiomark.on-dialog {
  top: 1px;
}

/* On mouse-over, add a grey background color */
.radiobtn-wrapper:hover input ~ .radiomark {
  background-color: #f2efff;
}

/* When the radio button is checked, add a blue background */
.radiobtn-wrapper input:checked ~ .radiomark {
  border: 1px solid var(--primary-color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radiomark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radiobtn-wrapper input:checked ~ .radiomark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radiobtn-wrapper .radiomark:after {
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--primary-color);
}

// Custom toggle switch

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.collapsedClass {
  transition: all 0.4s ease-in-out;

  .mat-expansion-indicator {
    display: none !important;
  }
}

.mat-calendar-body-selected {
  background-color: var(--primary-color) !important;
}

.mat-expansion-indicator::after {
  background-image: url("assets/images/icons/arrow.svg");
  background-position: center;
  background-size: 10px;
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  border-style: none !important;
  transform-origin: center;
  transform: rotate(0) !important;
}

.ag-theme-quartz {
  // height: 100vh;

  /* Changes the color of the grid text */
  // --ag-foreground-color: rgb(14, 68, 145);
  /* Changes the color of the grid background */
  --ag-background-color: #fff;
  /* Changes the header color of the top row */
  --ag-header-background-color: rgba(250, 249, 255, 0);
  /* Changes the hover color of the row*/
  --ag-row-hover-color: rgb(216, 226, 255);
  font-family: "Lexend", sans-serif !important;
}

.ag-grid-type-1 {
  .ag-theme-quartz {
  }
}

.ag-header-cell-text {
  font-weight: 600;
}

.ag-ltr .ag-cell {
  font-weight: 300;
  display: flex;
  align-items: center;
}

// side dialog mat header

.banking-side-dialog {
  .mat-mdc-tab-header {
    width: 90% !important;
  }
}

// Mat Menu Styles

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-family: "Lexend", sans-serif !important;
  font-size: 14px !important;
}

.mat-mdc-menu-panel {
  border-radius: 10px !important;
  padding: 5px !important;
}

// Mat Menu Styles Ends

.ag-root-wrapper-body.ag-layout-normal {
  height: auto !important;
  min-height: 100px !important;
}

ag-grid-angular {
  // height: calc(100vh - 235px);

  // @media screen and (max-width: $smallMedium) {
  //   height: calc(100vh - 352px);
  // }

  // @media screen and (max-width: $small) {
  //   height: calc(100vh - 208px);
  // }

  height: 100%;
}

// .attendance {
//   ag-grid-angular {
//     height: calc(100vh - 237px);

//     @media screen and (max-width: $smallMedium) {
//       height: calc(100vh - 360px);
//     }

//     @media screen and (max-width: $small) {
//       height: calc(100vh - 208px);
//     }
//   }
// }

// .tab-content-wrapper {
//   ag-grid-angular {
//     height: calc(100vh - 279px);

//     @media screen and (max-width: $smallMedium) {
//       height: calc(100vh - 352px);
//     }

//     @media screen and (max-width: $small) {
//       height: calc(100vh - 280px);
//     }
//   }
// }

// // Leave Tab under employee details
// .leave-history-table {
//   ag-grid-angular {
//     height: calc(100vh - 347px);

//     @media screen and (max-width: $smallMedium) {
//       height: calc(100vh - 352px);
//     }

//     @media screen and (max-width: $small) {
//       height: calc(100vh - 280px);
//     }
//   }
// }

// .working, .available, .active{
//   color: #00a860;
// }

// .completed {
//   color: #00a860;
// }

// .progress  {
//   color: #efbe12;
// }

// .on-leave, .under-maintenance, .disposed {
//   color: #ab0010;
// }

// .transferred {
//   color: #cc7707;
// }

.description-width {
  min-width: 100px;
}

// Use this class for color change
.green {
  color: #00a860;
}

.red {
  color: #ab0010;
}

.yellow {
  color: #efbe12;
}

.grey {
  color: #919191;
}

.blue {
  color: #007ed8;
}

// Use this class for color change

// filjo_M css section

.inner-content {
  flex-grow: 1;
}

.cmn-Search-select-container {
  .mat-mdc-select-trigger {
    padding-left: 30px;
    background-image: url(./assets/images/icons/icon-search.svg);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 0px;
  }
}

.item-service-adding-table-wrapper,
.invoice-new-form-wrapper {
  .mat-mdc-form-field-infix {
    width: auto !important;
  }
}

.cdk-global-overlay-wrapper {
  .cdk-overlay-pane {
    min-width: 700px !important;
    padding: 20px;

    @media screen and (max-width: 768px) {
      min-width: 90% !important;
    }
  }
}

.ag-body {
  min-height: 50px !important;
}

.ag-paging-panel {
  @media screen and (max-width: 576px) {
    justify-content: space-between !important;
  }
}

.ag-paging-row-summary-panel {
  @media screen and (max-width: 576px) {
    display: none !important;
  }
}

.ag-paging-page-summary-panel {
  @media screen and (max-width: 576px) {
    margin: 0 !important;
  }
}

.ag-label {
  @media screen and (max-width: 576px) {
    display: none !important;
  }
}

//

.toast-success {
  background-color: var(--primary-color) !important;
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 4%);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .loader {
    width: 80px;
    height: 80px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><linearGradient id="a9"><stop offset="0" stop-color="%23773A76" stop-opacity="0"></stop><stop offset="1" stop-color="%23773A76"></stop></linearGradient><circle fill="none" stroke="url(%23a9)" stroke-width="30" stroke-linecap="round" stroke-dasharray="0 44 0 44 0 44 0 44 0 360" cx="100" cy="100" r="70" transform-origin="center"><animateTransform type="rotate" attributeName="transform" calcMode="discrete" dur="2" values="360;324;288;252;216;180;144;108;72;36" repeatCount="indefinite"></animateTransform></circle></svg>');
    background-size: 80px;
    background-position: center;
    background-repeat: no-repeat;
  }
}

// Quill
.ql-editor {
  height: 100%;
}

quill-editor {
  width: 100%;
  height: 100%;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ece8ff;
  background-color: #f1eefe;
}

.ql-container.ql-snow {
  border: 1px solid #ece8ff;
}

// Remove arrows in number

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

// Remove arrows in number

.mat-mdc-form-field-error {
  font-size: 12px;
  font-family: "Lexend", sans-serif !important;
}

.custom-error {
  margin-top: -10px;
  margin-bottom: 0px;

  > span {
    position: static !important;
    font-weight: 400;
    text-transform: capitalize;
  }
}

.mdc-form-field > label {
  margin-bottom: 0;
}

.mat-mdc-form-field-bottom-align {
  &:has(.mat-mdc-form-field-hint-spacer:empty) {
    height: 10px !important;
  }
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  &::placeholder {
    font-size: 12px !important;
  }

  &::-ms-reveal {
    display: none;
  }
}

.reset-wrapper {
  .mat-mdc-form-field-bottom-align::before {
    display: none !important;
  }

  .mat-mdc-form-field-error-wrapper {
    position: static !important;
  }
}

.white-custom-mat-select {
  .mat-mdc-select-placeholder {
    color: #fff !important;
  }
}

.dash-color-one {
  background: linear-gradient(to right, #fecdff55, #fff3fd);
}

.dash-color-two {
  background: linear-gradient(to right, #cea8ff55, #f9f3ff);
}

.dash-color-three {
  background: linear-gradient(to right, #d4cdff55, #f3f4ff);
}

.dash-color-four {
  background: linear-gradient(to right, #8995ff55, #f3f3ff);
}

.dash-color-five {
  background: linear-gradient(to right, #cde1ff, #f3f3ff);
}
.dash-color-six {
  background: linear-gradient(to right, #a8e6ff55, #f3fcff);
}

.dash-color-seven {
  background: linear-gradient(to right, #a8ffc455, #f3fff8);
}

.dash-color-eight {
  background: linear-gradient(to right, #ffd9a855, #fff9f3);
}

.dash-color-nine {
  background: linear-gradient(to right, #ffc4e055, #fff3f9);
}
.dash-color-ten {
  background: linear-gradient(to right, #bfffc455, #f3fff3);
}

.dashboard-color-one {
  background: linear-gradient(
    34deg,
    rgba(137, 149, 255, 0.4) -6.13%,
    rgba(243, 243, 255, 0.07) 132.24%
  );
}
.dash-txt-color-one {
  color: #293281;
}
.dash-sub-one {
  color: #797fb3 !important;
}
.dash-add {
  margin-left: auto !important;
}

.dashboard-color-two {
  background: linear-gradient(
    34deg,
    rgba(212, 205, 255, 0.4) -6.13%,
    rgba(243, 244, 255, 0.07) 132.24%
  );
}
.dash-txt-color-two {
  color: #65379e;
}
.dash-sub-two {
  color: #a388c6 !important;
}

.dashboard-color-three {
  background: linear-gradient(
    34deg,
    rgba(206, 168, 255, 0.4) -6.13%,
    rgba(249, 243, 255, 0.07) 132.24%
  );
}
.dash-txt-color-three {
  color: #381d5a;
}
.dash-sub-three {
  color: #88769f !important;
}

.dashboard-color-four {
  background: linear-gradient(
    34deg,
    rgba(254, 205, 255, 0.4) -6.13%,
    rgba(255, 243, 253, 0.07) 132.24%
  );
}
.dash-txt-color-four {
  color: #7b2257;
}
.dash-sub-four {
  color: #b27c9e !important;
}

.dashboard-color-five {
  background: linear-gradient(
    34deg,
    rgba(156, 183, 255, 0.4) -6.13%,
    rgba(243, 254, 255, 0.07) 132.24%
  );
}
.dash-txt-color-five {
  color: #5e87c4;
}
.dash-sub-five {
  color: #81a7e2 !important;
}

.dashboard-color-six {
  background: linear-gradient(
    34deg,
    rgba(205, 225, 255, 0.4) -6.13%,
    rgba(255, 243, 243, 0.07) 132.24%
  );
}
.dash-txt-color-six {
  color: #2d49aa;
}
.dash-sub-six {
  color: #7d8fcb !important;
}

.dash-card-wrapper {
  border-radius: 20px;
  height: 100%;
  .dash-card-mini {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .dash-card {
    position: relative;
    padding: 20px 10px 10px 20px;
    background: url(./assets/images/icons/dash-bg-image.svg);
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 100%;

    h6 {
      font-weight: 300;
      font-size: 32px;
      margin-top: 20px;
    }

    .add-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;

      button {
        all: unset;
        cursor: pointer;
        border-radius: 100px;
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: #fff;
        border-radius: 100px;
        padding: 4px 4px 4px 10px;
        text-transform: capitalize;

        span {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #000;
          border-radius: 100px;

          img {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        color: #797fb3;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;

        span {
          margin-left: 7px;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }

    .arrow-icon {
      background-color: #000;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;

      position: absolute;
      top: 5px;
      right: 5px;

      img {
        width: 12px;
        aspect-ratio: 1;
      }
    }

    .head-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      svg {
        flex-shrink: 0;
      }
      h5 {
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
      }

      > div {
        width: 21px;
        aspect-ratio: 1;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.card-secondary {
  border-radius: 20px;
  padding: 20px 10px 10px 20px;
  height: 100%;
  background: linear-gradient(
    34deg,
    rgba(212, 205, 255, 0.4) -6.13%,
    rgba(243, 244, 255, 0.07) 132.24%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  .top-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    h5 {
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
  .bottom-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    > div {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      span,
      h6 {
        font-size: 14px;
        font-weight: 400;
        color: #65379e;
      }
    }
  }
}
.icon-adjust {
  height: 14px;
}

quill-editor {
  strong {
    font-weight: bold !important;

    em {
      font-style: italic !important;
      font-weight: bold !important;
    }
  }

  em {
    font-style: italic !important;
  }
}
.dash-view-all-btn {
  border-radius: 10px;
  border: 1px solid #dedede;
  padding: 10px;
  background-color: var(--white-color);
  cursor: pointer;
  font-family: "Lexend", sans-serif !important;
  transition: 0.3s all;
  &:hover {
    background-color: var(--black-color);
    color: var(--white-color);
  }
  @media screen and (max-width: 480px) {
    padding: 5px;
  }
}

.w-full {
  width: 100% !important;
}
.mat-error-pos {
  margin-top: -10px;
  margin-bottom: 10px;
}

.reload-wrapper.rotate img {
  transform: rotate(360deg);
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: #000 !important;
}
